<template>
<div class="poisearch-container">
    <div class="searchbar" :class="{'with-expansion': suggestions.length > 0 || filtersVisible}">
        <img src="../assets/icons/search/location.png" style="height: 30px; width: auto;" @click="focusInput" />
        <vs-input id="poiSearch" :placeholder="$t('explore.search.placeholder')" :loading="timer != null" v-model="search" v-on:keyup.enter="enterEvent" />
        <vs-button circle transparent @click="filtersVisible = !filtersVisible">
            <vs-avatar badge-color="primary" badge-position="top-right" size="28" square>
                <img v-if="filtersVisible" src="../assets/icons/search/filters-active.png" style="width: 24px;" />
                <img v-else src="../assets/icons/search/filters.png" style="width: 24px;" />

                <template #badge v-if="Object.keys(filters).length - Object.values(filters).filter(e => (
                        (typeof(e) == 'boolean' && e == false) ||
                        (typeof(e) == 'object' && e == null) ||
                        (typeof(e) == 'object' && e.length == 0)
                    )).length > 0">
                    {{ Object.keys(filters).length - Object.values(filters).filter(e => (
                        (typeof(e) == 'boolean' && e == false) ||
                        (typeof(e) == 'object' && e == null) ||
                        (typeof(e) == 'object' && e.length == 0)
                    )).length }}
                </template>
            </vs-avatar>

        </vs-button>
        <vs-button circle class="search-btn" @click="doSearch()">
            <img src="../assets/icons/search/search.png" style="width: 18px;" />

        </vs-button>
    </div>
    <div class="suggestions" v-if="!filtersVisible">
        <SearchSuggestion v-for="suggestion in suggestions" :key="suggestion.id" :poi="suggestion" @click.native="chooseSuggestion(suggestion)" />
        <div v-if="noResult">
            <p style="margin: 0; padding: 20px;">{{ $t('explore.search.noResults') }}</p>
        </div>
    </div>

    <SearchFilters :user="user" :auto="auto" v-show="filtersVisible" v-if="!isMobile" :oldfilters="filters" :change="applyFilters" :close="() => {filtersVisible = false}" />

    <slideout-panel></slideout-panel>

</div>
</template>

<script>
import SearchFilters from './SearchFilters.vue';
import SearchSuggestion from './SearchSuggestion.vue';
import {
    apiCall
} from '../utils/ApiMiddleware';
export default {
    name: "poiSearch",
    props: {
        isMobile: Boolean,
        userCoords: {
            type: Array,
            default: function () {
                return null;
            }
        },

        auto: { // load suggestions
            type: Boolean,
            default: true
        },

        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            suggestions: [],
            search: '',
            filtersVisible: false,
            filtersMobilePanel: null,
            timer: null,
            filters: {
                country_id: null,
                state_id: null,
                city_id: null,
                target_ids: [],
                only_place_to_discover: false,
                only_my_places: false,
                ratings: [],
            },
            noResult: false,
            nearestPoi: null
        }
    },

    created() {
        document.addEventListener("click", this.handleOutOfElementClick);
    },

    beforeDestroy() {
        document.removeEventListener("click", this.handleOutOfElementClick);
    },

    watch: {
        search(n) {
            if (!this.auto) {
                return;
            }
            this.noResult = false;
            if (n == '') {
                this.suggestions = []
                if (this.timer != null) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
            } else {
                if (this.timer != null) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.timer = setTimeout(() => {
                    this.doSearch()
                }, 1000);
            }
        },
        filtersVisible(n) {
            if (n) {
                this.noResult = false;
            }
            var vm = this;
            if (!this.isMobile) {
                return;
            }

            if (n) {
                this.filtersMobilePanel = this.$showPanel({
                    component: SearchFilters,
                    openOn: 'bottom',
                    props: {
                        change: this.applyFilters,
                        close: () => {
                            this.filtersVisible = false
                        },
                        oldfilters: this.filters,
                        user: this.user
                    }
                });
                this.filtersMobilePanel.promise
                    .then(() => {
                        vm.filtersVisible = false
                    });
            } else {
                if (this.filtersMobilePanel != null) {
                    this.filtersMobilePanel.hide();
                    this.filtersMobilePanel = null;
                }
            }

        }
    },
    methods: {
        handleOutOfElementClick(evt) {
            const flyoutEl = document.getElementsByClassName('poisearch-container')[0];
            // .vs-tootip is the class of the tooltip
            const flyoutEl2 = document.getElementsByClassName('vs-tooltip')[0];
            let targetEl = evt.target; // clicked element      
            do {
                if (targetEl == flyoutEl || targetEl == flyoutEl2 || (targetEl.className && targetEl.className == 'vs__deselect')){
                    return;
                }
                // Go up the DOM
                targetEl = targetEl.parentNode;
            } while (targetEl);
            // This is a click outside.      
            this.suggestions = [];
            this.filtersVisible = false;
            this.noResult = false;
        },

        enterEvent() {
            if (!this.auto) {
                this.doSearch();
            }
        },

        async applyFilters(filters) {
            if (filters != null) {
                this.filters = filters;
            }

            await this.doSearch(false);
            // apply filters to the map too
            this.$emit('search', {
                country_id: this.filters.country_id,
                state_id: this.filters.state_id,
                city_id: this.filters.city_id,
                target_ids: this.filters.target_ids,
                only_place_to_discover: this.filters.only_place_to_discover,
                only_my_places: this.filters.only_my_places,
                ratings: this.filters.ratings,
            }, this.nearestPoi)

        },

        async chooseSuggestion(suggestion) {
            this.search = '';
            this.suggestions = []
            if (suggestion.isCity || suggestion.isRegion || suggestion.isCountry) {
                const response = await apiCall('GET', '/pois', {
                    city_id: suggestion.isCity ? suggestion.id : undefined,
                    state_id: suggestion.isRegion ? suggestion.id : undefined,
                    country_id: suggestion.isCountry ? suggestion.id : undefined,
                    limit: 1
                });
                if (response.status == 200) {
                    if (response.data.data != null && response.data.data.length > 0) {
                        var nearestPoi = response.data.data[0];
                        nearestPoi.forced = true;
                        if (suggestion.isRegion) { // decrease zoom if is region
                            nearestPoi.preferredZoom = 9;
                        }

                        if(suggestion.isCountry){ // decrease more if is a whole country
                            nearestPoi.preferredZoom = 7;
                        }
                        this.$emit('search', {
                            state_id: this.filters.state_id,
                            city_id: this.filters.city_id,
                            country_id: this.filters.country_id,
                            target_ids: this.filters.target_ids,
                            only_place_to_discover: this.filters.only_place_to_discover,
                            only_my_places: this.filters.only_my_places,
                            ratings: this.filters.ratings,
                        }, nearestPoi)
                    } else {
                        this.suggestions = [];
                        this.noResult = true;
                    }
                } else if (response.status != 0) {
                    this.$vs.notification({
                        title: this.$t('common.messages.somethingWentWrong'),
                        text: this.$t('explore.search.error'),
                        color: 'danger',
                        position: 'top-right'
                    })
                }
            } else {
                this.$router.push({
                    name: 'Scheda del luogo',
                    params: {
                        id: suggestion.id
                    }
                })
            }

        },
        focusInput() {
            document.getElementById("vs-input--poiSearch").focus();
        },

        async doSearch(suggestions = true) {
            if (!this.auto) {
                suggestions = false;
            }

            this.nearestPoi = null;
            this.noResult = false;
            var params = {
                search: this.search,
                limit: 3,
                actual_latitude: this.userCoords != null ? this.userCoords[0] : undefined,
                actual_longitude: this.userCoords != null ? this.userCoords[1] : undefined,
            };

            if (this.filters != null) {
                params = {
                    ...params,
                    ...this.filters
                };
            }

            this.$emit('query', this.search);

            const response = await apiCall('GET', '/pois', params);
            this.timer = null;
            if (response.status == 200) {
                if (response.data.data != null && response.data.data.length > 0) {
                    if (suggestions) {
                        this.suggestions = response.data.data;
                    } else {
                        this.suggestions = [];
                        this.nearestPoi = response.data.data[0];
                    }
                } else {
                    this.suggestions = [];
                    this.noResult = true;
                }

                this.filtersVisible = false;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('explore.search.error'),
                    color: 'danger',
                    position: 'top-right'
                })
            }

            // Search for regions
            const response4 = await apiCall('GET', '/places/countries', {
                search: this.search,
                limit: 3
            });
            if (response4.status == 200) {
                if (response4.data.data != null && response4.data.data.length > 0) {
                    if (suggestions) {
                        this.suggestions = response4.data.data.map(e => ({
                            ...e,
                            isCountry: true
                        })).concat(this.suggestions);
                    }
                }
            } else if (response4.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('explore.search.error'),
                    color: 'danger',
                    position: 'top-right'
                })
            }


            // Search for regions
            const response3 = await apiCall('GET', '/places/states', {
                search: this.search,
                limit: 3
            });
            if (response3.status == 200) {
                if (response3.data.data != null && response3.data.data.length > 0) {
                    if (suggestions) {
                        this.suggestions = response3.data.data.map(e => ({
                            ...e,
                            isRegion: true
                        })).concat(this.suggestions);
                    }
                }
            } else if (response3.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('explore.search.error'),
                    color: 'danger',
                    position: 'top-right'
                })
            }

            // Search for cities
            const response2 = await apiCall('GET', '/places/cities', {
                search: this.search,
                limit: 3
            });
            if (response2.status == 200) {
                if (response2.data.data != null && response2.data.data.length > 0) {
                    if (suggestions) {
                        this.suggestions = response2.data.data.map(e => ({
                            ...e,
                            isCity: true
                        })).concat(this.suggestions);
                    }
                }
            } else if (response2.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('explore.search.error'),
                    color: 'danger',
                    position: 'top-right'
                })
            }

            if (this.suggestions.length > 0) {
                this.noResult = false;
            }
        }
    },
    components: {
        SearchSuggestion,
        SearchFilters
    },

}
</script>

<style scoped>
.poisearch-container {
    position: absolute;
    left: 80px;
    top: 15px;
    z-index: 2100;
    display: flex;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;
}

.suggestions {
    background: white;
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
}

.search-btn {
    width: 40px;
    height: 40px;
}

.searchbar {
    background: white;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.22);
    z-index: 1950;

}

.searchbar.with-expansion {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/* MOBILE */
@media (max-width: 600px) {
    .poisearch-container {
        top: 20px;
        left: 0;
        padding-left: 25px;
        padding-right: 25px;
        width: 100%;
    }

    input {
        flex-grow: 1;
    }

    .search-btn {
        width: 45px;
        height: 45px;
        min-width: 45px;
    }
}
</style><style>
.poisearch-container .vs-input {
    background: white;
}

/* MOBILE */
@media (max-width: 600px) {

    ::placeholder {
        font-size: 18px !important;
        color: rgba(var(--grey-75));
    }

    input.vs__search {
        height: 30px;
    }

    #poiSearch {
        width: calc(100% - 130px) !important;
    }
}


.poisearch-container .vs-avatar {
    background: transparent!important;
    box-shadow: none!important;
}

.poisearch-container .vs-avatar-content:hover img {
    transform: unset!important;
}
</style>
